function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16))
}

function uppercase(string: string) {
  return string.charAt(0).toUpperCase() + string.substring(1).toLowerCase()
}

function slugify(string: string) {
  return string.replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-/g, '') // Replace multiple - with single -
    .trim()
}

function snakeCase(string: string) {
  return string.toLowerCase()
    .replace(/[^\w\-]+/g, '_') // Remove all non-word chars
    .replace(/\-/g, '_') // Replace multiple - with single -
    .trim()
}

function randomString(length: number) {
  let text = ''
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (let i = 0; i < length; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length))

  return text
}

function isEmail(email: string) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email.toLowerCase())
}

function escapeHtml(text: string) {
  const map = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    '\'': '&#039;',
  }

  return text.replace(/[&<>"']/g, (m) => { return map[m] })
}

function clipString(text: string, length: number, html: boolean) {
  if (typeof html === 'undefined')
    html = false

  if (typeof text !== 'undefined' && text !== null && html)
    text = escapeHtml(text)

  // TODO: I removed a "text-clipper" package that we need to replace with something else
  // return clip(text, length, {html: html})
}

function genUniqueKey(item: object) {
  const UNIQUE_KEY_PROP = '__unique_key_prop__'
  const KEY_PREFIX = `__key_prefix__${Date.now()}_`
  let uid = 0

  const genUniqueKey = (obj: object) => {
    if (typeof obj === 'object') {
      if (UNIQUE_KEY_PROP in obj)
        return obj[UNIQUE_KEY_PROP]

      const value = KEY_PREFIX + uid++
      Object.defineProperty(obj, UNIQUE_KEY_PROP, { value })
      return value
    }
    return obj
  }

  return genUniqueKey(item)
}

export default {
  uuidv4,
  uppercase,
  slugify,
  snakeCase,
  randomString,
  isEmail,
  escapeHtml,
  clipString,
  genUniqueKey,
}
