<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod'
import { usePageStore } from '@voix/store/pageStore'
import { useField, useForm } from 'vee-validate'
import * as zod from 'zod'

interface Props {
  backgroundColor?: string
  inputBackgroundColor?: string
  inputColor?: string
  buttonBackgroundColor?: string
  buttonColor?: string
}

// Props
const {
  backgroundColor = 'rgb(37, 66, 151)',
  inputBackgroundColor = 'rgb(57, 87, 175)',
  inputColor = '#fff',
  buttonBackgroundColor = 'rgb(31, 176, 215)',
  buttonColor = '#fff',
} = defineProps<Props>()

// State
const show = ref(true)
const formName = ref('page_email_signup')
const complete = ref(false)
const validationSchema = toTypedSchema(
  zod.object({
    email: zod.string({ message: '*Email is required' }).email({ message: '*Must be a valid Email' }),
    terms: zod.boolean().refine(val => val === true, { message: '*Must agree to terms.' }),
  }),
)
const { handleSubmit, errors } = useForm({
  validationSchema,
})
const { value: email } = useField('email')
const { value: terms } = useField('terms')
const onSubmit = handleSubmit(() => submitForm())

// Computed
const currentPage = computed(() => {
  const pageStore = usePageStore()
  return pageStore.currentPage
})

// Methods
function toggleTerms() {
  terms.value = !terms.value
}
function submitForm() {
  if (currentPage.value.site_id === 1 && window.dataLayer) {
    window.dataLayer.push({
      event: 'e_newsletterSignup',
    })
  }

  const data = {
    site_id: 1,
    form: formName.value,
    agree_to_terms: terms.value ? 1 : 0,
    first_name: '',
    last_name: '',
    email: email.value,
    data: {},
  }

  $voixFetch('/api/form-signup', { method: 'post', body: data }).then(() => {
    complete.value = true
  }).catch((e) => {
    const { errors } = e.response.data
    console.error(errors)
  })
}

// Lifecycle
onMounted(() => {
  const { snakeCase } = useStrings
  if (currentPage.value && Object.prototype.hasOwnProperty.call(currentPage.value, 'title'))
    formName.value = `page_${snakeCase(currentPage.value.title)}_email_signup`

  terms.value = false

  // TODO: Fix bus system
  // window.bus.$on('sapphire-replacement', () => {
  //   this.show = false
  // })
})
</script>

<template>
  <div v-if="show" class=" bg-orange-50 h-auto md:h-96 pt-20 mb-0 md:mb-32">
    <div
      class="relative tracking-footer-sign-up text-white text-center flex flex-col items-center pt-16 pb-8 px-8 md:p-12 mb-20 mx-auto"
      :style="{
        backgroundColor,
      }"
      style="max-width: 1248px"
    >
      <div
        class="text-4xl font-sans font-light uppercase leading-tight tracking-wider mb-6 md:mb-2"
      >
        Be the first to know
      </div>
      <div
        class="font-sans text-sm uppercase tracking-widest mb-12 md:mb-8 mx-6 md:mx-0"
      >
        SIGN UP TO RECEIVE ATLANTIS NEWSLETTERS AND OFFERS IN YOUR INBOX
      </div>
      <form v-if="!complete" @submit="onSubmit">
        <div class="w-full flex justify-center mb-6">
          <div class="flex w-full justify-center md:w-3/5">
            <div class="flex flex-col">
              <input
                id="footer-signup-email"
                v-model="email"
                type="text"
                class="placholder-white text-base leading-none font-sans2 w-[332px] sm:w-[310px] h-[51px] px-6 py-3 placeholder:text-white/50"
                :class="{
                  'bg-glueblue-600': !inputColor,
                }"
                :style="{
                  backgroundColor: inputBackgroundColor || '',
                  color: inputColor || '',
                }"
                name="email"
                placeholder="Enter Email Address"
              >
              <span class="self-start text-xs mt-1">
                {{ errors.email }}
              </span>
            </div>

            <input
              type="submit"
              class="cursor-pointer bg-glueblue-400 text-xs font-bold tracking-widest hidden uppercase font-sans pb-1 px-8 leading-none sm:block"
              style="height: 51px; width: 184px"
              value="Send me offers"
              :class="{
                'bg-glueblue-400': !buttonColor,
              }"
              :style="{
                backgroundColor: buttonBackgroundColor,
                color: buttonColor,
              }"
            >
          </div>
        </div>

        <div>
          <div class="relative flex flex-col justify-center items-center mb-8">
            <label
              for="footer-newsletter-agree-to-terms"
              class="relative font-sans2 flex max-w-[418px] flex-wrap pb-2"
            >
              <div
                class="w-4 h-4 md:w-6 md:h-6 mr-2 cursor-pointer flex justify-center items-center"
                :class="{
                  'bg-glueblue-600': !inputColor,
                }"
                :style="{
                  backgroundColor: inputBackgroundColor,
                  color: inputColor,
                }"
                @click="toggleTerms"
              >
                <Icon v-show="terms" name="heroicons:check" class="w-6 h-6 md:w-8 md:h-8" />
              </div>

              <div class="text-left text-xs md:text-sm lg:text-base md:text-center">
                I am over the age of 18 and have read the
                <a
                  href="https://www.atlantisbahamas.com/privacy "
                  class="border-b-2  pb-1 text-white"
                  :class="{
                    'border-glueblue-400': !buttonColor,
                  }"
                  :style="{
                    borderColor: buttonBackgroundColor,
                  }"
                >
                  Privacy Policy
                </a>
              </div>

              <span class="absolute left-0 -bottom-3 text-xs">
                {{ errors.terms }}
              </span>
            </label>

            <div class="w-full md:w-1/2 text-xs font-sans2 opacity-50 mt-4">
              By submitting this form, I confirm I would like to receive emails on
              promotions, special offers and updates from Atlantis Paradise Island and its
              affiliated brands.
            </div>
          </div>

          <input
            type="submit"
            class="bg-glueblue-400 cursor-pointer text-xs font-bold tracking-widest uppercase w-full font-sans px-8 py-4 leading-none sm:hidden"
            value="Send me offers"
            :class="{ 'bg-glueblue-600': !inputColor }"

            :style="{
              backgroundColor: inputBackgroundColor,
              color: inputColor,
            }"
            @click="onSubmit"
          >
        </div>
      </form>

      <div v-else class="text-xl font-sans uppercase">
        Thank you, we have added you to our newsletter
      </div>
    </div>
  </div>
</template>
